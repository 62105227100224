import React from "react";
import "./WorkInProgress.scss";
import { Link } from "react-router-dom";
import comingsoon from "../../images/pexels-vie-studio-4439444.jpg";

const ComingSoon = () => {
   return (
      <div className="coming-soon">
         <div className="container text-center">
            <img className="img-fluid" src={comingsoon} alt="" />
            <h3>Work in progress</h3>
            <Link to="/home">Back to home</Link>
         </div>
      </div>
   );
};

export default ComingSoon;
