import React from "react";
import "./FeatureSection.scss";
import icon1 from "../../images/ebook.png";
import icon2 from "../../images/ring.png";
import icon3 from "../../images/book.png";

const FeatureSection = () => {
   return (
      <div className="feature-section">
         <div className="container">
            <div className="header-text text-center">
               <h3>Why CodeCrafters</h3>
               <p>At CodeCrafters, we don't just teach coding – we inspire a lifelong passion for learning and problem-solving.</p>
            </div>
            <div className="row">
               <div className="col-md-4">
                  <div className="feature-box">
                     <div className="icon">
                        <img src={icon1} alt="" />
                     </div>
                     <h5>Discover the Joy of Coding</h5>
                     <p>
                        From Python to JavaScript, your child will learn the languages that drive the digital world.
                     </p>
                  </div>
               </div>
               <div className="col-md-4">
                  <div className="feature-box">
                     <div className="icon icon2">
                        <img src={icon2} alt="" />
                     </div>
                     <h5>Learn from the Best</h5>
                     <p>
                     Our instructors aren't merely teachers; they’re successful programmers who bring practical experience into the learning environment.
                     </p>
                  </div>
               </div>
               <div className="col-md-4">
                  <div className="feature-box">
                     <div className="icon icon3">
                        <img src={icon3} alt="" />
                     </div>
                     <h5>Adapt to Future Challenges</h5>
                     <p>
                     We emphasise the 'how' of learning, equipping children with the tools to confidently tackle new challenges.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default FeatureSection;
