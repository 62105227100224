import React from "react";
import aboutImg from "../../images/codecraft.png";
import FeatureSection from "../FeatureSection/FeatureSection";
import "./About.scss";

const About = () => {
   return (
      <>
         <div className="about">
            <div className="container">
               <div className="row align-items-center">
                  <div className="col-md-6 pe-md-5 mb-5 mb-md-0">
                     <img className="img-fluid shadow" src={aboutImg} alt="" />
                  </div>
                  <div className="col-md-6">
                     <div className="about-info">
                        <h3>Know About Us</h3>
                        <p>
                        Welcome to CodeCrafters, the premier destination for the tech leaders of tomorrow.
                        </p> 
                        <p>
                        We specialise in igniting the spark of creativity and innovation in young minds through the thrilling realm of programming. 
                        </p>
                        <p>
                        With a curriculum tailored for children, we offer both face-to-face and online teaching options to accommodate your preferences and lifestyle.
                        </p>
                        <ul>
                           <li>Creative Study Pattern</li>
                           <li>Quick Crash Courses</li>
                           <li>Certification Awarded</li>
                           <li>Provided with Experimental Examples</li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <FeatureSection />
      </>
   );
};

export default About;
