import { useEffect, useState } from "react";
import getCourseAPI from "../settings/api_setting";

const useCourses = () => {
   const [menus, setMenus] = useState([]);
    
    useEffect(() => {
      fetch(getCourseAPI)
         .then((res) => res.ok ? res.json() : Promise.reject(`HTTP error! status: ${res.status}`))
         .then((data) => setMenus(data))
         .catch((error) => {
            console.error('Error fetching data:', error);
            setMenus([
              {
                _id: "1",
                title: " Ages 5-8",
                category: "Little Coders",
                image: "https://camo.githubusercontent.com/3a94765acd5480a31e4721c435e83af42540b63df6f8cba614b04f966b7b573b/68747470733a2f2f643337726669363367326f6c62332e636c6f756466726f6e742e6e65742f77702d636f6e74656e742f75706c6f6164732f323031382f30322f31313131343234312f736372617463685f6576656e5f6f725f6f6464322e706e67",
                lessons: 10,
                price: 200,
                rating: 4.5,
                review: 50,
                duration: "10 hours"
              },
              {
                _id: "2",
                title: "Ages 9-12",
                category: "Junior Developers",
                image: "https://images.unsplash.com/photo-1529429612779-c8e40ef2f36d?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
                lessons: 12,
                price: 250,
                rating: 4.7,
                review: 75,
                duration: "15 hours"
              },
              {
                _id: "3",
                title: "Ages 13-17",
                category: "Teen Innovators",
                image: "https://images.unsplash.com/photo-1607799279861-4dd421887fb3?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", 
                lessons: 15,
                price: 300,
                rating: 4.8,
                review: 175,
                duration: "15 hours"
              },
              // Add more mock courses as needed
            ]); // Set an appropriate fallback value
         });
   }, []);
   
  //  useEffect(() => {
  //     fetch(getCourseAPI)
  //        .then((res) => res.json())
  //        .then((data) => setMenus(data));
  //  }, []);

   return [menus];
};

export default useCourses;
