import React from "react";
import { Routes, Route, Link, useMatch } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThLarge, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import NotFound from "../../NotFound/NotFound";
import TopHeader from "../TopHeader/TopHeader";
import useAuth from "../../../hooks/useAuth";
import "./Dashboard.scss";
import AddCourse from "../AddCourse/AddCourse";
import ManageCourses from "../ManageCourses/ManageCourses";
import ManageOrders from "../ManageOrders/ManageOrders";
import MakeAdmin from "../MakeAdmin/MakeAdmin";
import Contacts from "../Contacts/Contacts";
import ManageUsers from "../ManageUsers/ManageUsers";

const Dashboard = () => {
  const { path } = useMatch();
  const { logOut } = useAuth();

  return (
    <>
      <TopHeader />

      <div className="container-fluid">
        <div className="row">
          <nav
            id="sidebarMenu"
            className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
          >
            <div className="position-sticky">
              <ul className="nav flex-column">
                <li className="nav-item">
                  <Link className="link" to={`${path}/addCourse`}>
                    <FontAwesomeIcon className="fa-icon" icon={faThLarge} /> Add Course
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="link" to={`${path}/manageCourses`}>
                    <FontAwesomeIcon className="fa-icon" icon={faThLarge} /> Manage Courses
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="link" to={`${path}/manageOrders`}>
                    <FontAwesomeIcon className="fa-icon" icon={faThLarge} /> Manage Orders
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="link" to={`${path}/contacts`}>
                    <FontAwesomeIcon className="fa-icon" icon={faThLarge} /> Manage Contacts
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="link" to={`${path}/manageUsers`}>
                    <FontAwesomeIcon className="fa-icon" icon={faThLarge} /> Manage Users
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="link" to={`${path}/makeAdmin`}>
                    <FontAwesomeIcon className="fa-icon" icon={faThLarge} /> Make Admin
                  </Link>
                </li>
                <li className="nav-item">
                  <span className="link" style={{ cursor: "pointer" }} onClick={logOut}>
                    <FontAwesomeIcon className="fa-icon" icon={faSignOutAlt} /> Logout
                  </span>
                </li>
              </ul>
            </div>
          </nav>

          <main className="col-md-9 ms-sm-auto col-lg-10 p-0 main">
            <Routes>
              <Route path={path} element={<h1>Dashboard</h1>} />
              <Route path={`${path}/addCourse`} element={<AddCourse />} />
              <Route path={`${path}/manageCourses`} element={<ManageCourses />} />
              <Route path={`${path}/manageOrders`} element={<ManageOrders />} />
              <Route path={`${path}/contacts`} element={<Contacts />} />
              <Route path={`${path}/manageUsers`} element={<ManageUsers />} />
              <Route path={`${path}/makeAdmin`} element={<MakeAdmin />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
