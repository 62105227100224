import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from "./components/About/About";
import Header from "./components/Header/Header";
import NotFound from "./components/NotFound/NotFound";
import Main from "./components/Main/Main";
import Contact from "./components/Contact/Contact";
import ComingSoon from "./components/WorkInProgress/WorkInProgress";
import Footer from "./components/Footer/Footer";
import CoursePage from "./components/CoursePage/CoursePage";
import Login from "./components/LoginRegister/Login";
import Register from "./components/LoginRegister/Register";
import AuthProvider from "./contexts/AuthProvider";
import CourseDetails from "./components/CourseDetails/CourseDetails";
import Cart from "./components/Cart/Cart";
import Dashboard from "./components/AdminPanel/Dashboard/Dashboard";
import SyncLoader from "react-spinners/SyncLoader";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { useEffect, useState } from "react";

function App() {
   const [loading, setLoading] = useState(false);
   useEffect(() => {
      setLoading(true);
      setTimeout(() => {
         setLoading(false);
      }, 3000);
   }, []);

   return (
      <AuthProvider>
         {loading ? (
            <div className="home-pre-loader">
               <div className="d-flex justify-content-center align-items-center">
                  <SyncLoader
                     className="syncloader"
                     color={"#2f2d52"}
                     loading={loading}
                     size={20}
                  />
               </div>
            </div>
         ) : (
            <>
 <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/home" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/courses" element={<CoursePage />} />
        <Route path="/course/:courseId" element={<CourseDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        {/* <Route path="/cart" element={<PrivateRoute><Cart /></PrivateRoute>} /> */}
        <Route path="/cart" element={<ComingSoon />} />
        <Route path="/admin" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router>
            </>
         )}
      </AuthProvider>
   );
}

export default App;