import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";
import paymentGateway from "../../images/online-payment.png";

const Footer = () => {
   return (
      <div className="footer">
         <div className="container">
            <div className="row">
               <div className="col-sm-6 col-lg-3">
                  <h3 className="logo mb-3">Code Crafters</h3>
                  <p>
                  Welcome to CodeCrafters, the premier destination for the tech leaders of tomorrow. <br/>
                  </p>
               </div>
               <div className="col-sm-6 col-lg-3">
                  <h5>Useful Links</h5>
                  <ul className="navbar-nav">
                     <li className="nav-item">
                        <Link className="link" to="/home">
                           Home
                        </Link>
                     </li>
                     <li className="nav-item">
                        <Link className="link" to="/courses">
                           Courses
                        </Link>
                     </li>
                     <li className="nav-item">
                        <Link className="link" to="/about">
                           About Us
                        </Link>
                     </li>
                     <li className="nav-item">
                        <Link className="link" to="/contact">
                           Contact
                        </Link>
                     </li>
                  </ul>
               </div>
               <div className="col-sm-6 col-lg-3">
                  <h5>Contact Us</h5>
                  <p>
                     Hoang Minh Nguyen <br />
                     39 Estate Road, <br /> Middle Park QLD 4074,
                     Australia.
                  </p>
                  <p>043 555 2268</p>
                  <p>admin@kidprogramming.net</p>
               </div>
               <div className="col-sm-6 col-lg-3">
                  <img className="img-fluid" src={paymentGateway} alt="" />
               </div>
            </div>
         </div>
      </div>
   );
};

export default Footer;
